module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"MDT Faucet | Get MDT on Rinkeby Testnet","short_name":"MDT Faucet","description":"Need MDT for MeFi Oracle testing? Get your Measurable Data Tokens (MDT) on Rinkeby Testnet here. It's all free and officially supported by Measurable Data Token.","start_url":"/","background_color":"#fff","theme_color":"#f1f5f9","display":"browser","icon":"src/images/favicon.png","lang":"en","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"7c359dd942adb25d06fdd6b4c6e0d503"},
    },{
      plugin: require('../node_modules/gatsby-plugin-web-font-loader/gatsby-browser.js'),
      options: {"plugins":[],"google":{"families":["Inter"]}},
    }]
